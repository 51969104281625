import { makeAutoObservable } from "mobx";
import moment from "moment";

export default class LoadingStore {
  isLoading: boolean = false;

  resposne: { isSuccess?: boolean; message: string } = { message: "" };

  loadingList: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (temp: boolean) => {
    // console.log("set loading 1 @" + moment().format("DD MMM YYYY HH:mm:ss"));
    this.isLoading = temp;
    // console.log("set loading 2 @" + moment().format("DD MMM YYYY HH:mm:ss"));

    if (temp === true) {
      // console.log("set loading 3 @" + moment().format("DD MMM YYYY HH:mm:ss"));

      this.setResonse("");
      // console.log("set loading 4 @" + moment().format("DD MMM YYYY HH:mm:ss"));
    }
    // console.log("set loading 5 @" + moment().format("DD MMM YYYY HH:mm:ss"));
  };

  setLoadingList = (action: string, temp: string) => {
    if (action === "add") {
      this.loadingList.push(temp);
    } else if (action === "remove") {
      this.loadingList = this.loadingList.filter((x) => x != temp);
    }
  };

  setResonse = (temp: string, isSuccess?: boolean) => {
    this.resposne = { isSuccess: isSuccess, message: temp };
  };
}
