import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import { createBrowserHistory } from "history";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store, StoreContext } from "./mobxStores/store";
import AuthComponent from "./commonComponent/AuthandLogin/AuthComponent";

// export const history = createBrowserHistory();

var googleClientId = "";
if (process.env.REACT_APP_BASE_GOOGLECLIENTID !== undefined) {
  googleClientId = process.env.REACT_APP_BASE_GOOGLECLIENTID;
}

// <Router location={location} navigator={navigator}>
// </Router>,

ReactDOM.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={googleClientId}>
      <StoreContext.Provider value={store}>
        <App />
        <AuthComponent />
      </StoreContext.Provider>
    </GoogleOAuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
